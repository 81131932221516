import styles from "../../styles/budgetmate/PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
    return (
        <div className={styles.main_content}>
            <img id={styles.app_icon} src="/appIcons/budgetmate-inverted.png" />
            <p className={styles.section_header}>Budgetmate Privacy Policy</p>
            <p className={styles.section_body}>This privacy policy applies to the Budgetmate app (hereby referred to as "Application") for mobile devices that was created by Bytemark Labs Technologies Inc (hereby referred to as "Service Provider") as a Free service. This service is intended for use "AS IS".</p>
        
            <p className={styles.section_header}>Information Collection and Use</p>
            <p className={styles.section_body}>The Application collects information when you download and use it. This information may include:</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Your device's Internet Protocol address (e.g., IP address)</li>
                <li className={styles.list_item}>The pages of the Application that you visit, the time and date of your visit, the time spent on those pages</li>
                <li className={styles.list_item}>The time spent on the Application</li>
                <li className={styles.list_item}>The operating system you use on your mobile device</li>
                <li className={styles.list_item}>Financial account information (such as bank account numbers, transaction data, account balances) accessed through Plaid</li>
            </ul>
            <p className={styles.section_body}>The Application does not gather precise information about the location of your mobile device.</p>
            <p className={styles.section_body}>The Service Provider may use the information you provided to contact you from time to time to provide you with important information, required notices and marketing promotions.</p>
            <p className={styles.section_body}>For a better experience, while using the Application, the Service Provider may require you to provide us with certain personally identifiable information, including but not limited to email, user id, full name. The information that the Service Provider request will be retained by them and used as described in this privacy policy.</p>
            
            <p className={styles.section_header}>Use of Plaid Services</p>
            <p className={styles.section_body}>The Application uses Plaid Technologies, Inc. ("Plaid") to provide users with secure connections to their bank accounts and to retrieve financial information necessary for the Application's functionality.</p>
            <p className={styles.section_body}>By using the Application, you acknowledge and agree that:</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Data Access and Transmission: You grant the Service Provider and Plaid the right, power, and authority to access and transmit your personal and financial information as reasonably necessary to provide the services of the Application.</li>
                <li className={styles.list_item}>Plaid's Privacy Practices: Your personal and financial information will be transferred, stored, and processed by Plaid in accordance with the Plaid Privacy Policy.</li>
            </ul>
        
            <p className={styles.section_header}>Information Collected via Plaid</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Account Information: Account holder name, account type, transaction history.</li>
                <li className={styles.list_item}>Transaction Data: Dates, descriptions, amounts, and categories of your transactions.</li>
            </ul>

            <p className={styles.section_header}>How We Use Information from Plaid</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Providing Services: To categorize your transactions into "Wants" or "Needs" and to provide insights into your spending and savings.</li>
                <li className={styles.list_item}>Improving Functionality: To enhance and personalize your experience within the Application.</li>
            </ul>

            <p className={styles.section_header}>Third-Party Access</p>
            <p className={styles.section_body}>The Application utilizes third-party services that have their own privacy policies about handling data. Below are the links to the privacy policies of the third-party service providers used by the Application:</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}><a className={styles.link} href="https://plaid.com/legal/#end-user-privacy-policy">Plaid</a></li>
            </ul>
            <p className={styles.section_body}>Only aggregated, anonymized data is periodically transmitted to external services to aid the Service Provider in improving the Application and their service. The Service Provider may share your information with third parties in the ways that are described in this privacy statement.</p>

            <p className={styles.section_header}>Disclosure of Information</p>
            <p className={styles.section_body}>The Service Provider may disclose User Provided and Automatically Collected Information:</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Legal Requirements: As required by law, such as to comply with a subpoena or similar legal process.</li>
                <li className={styles.list_item}>Protection and Safety: When they believe in good faith that disclosure is necessary to protect their rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.</li>
                <li className={styles.list_item}>Service Providers: With trusted service providers who work on their behalf, do not have an independent use of the information disclosed to them, and have agreed to adhere to the rules set forth in this privacy statement.</li>
            </ul>

            <p className={styles.section_header}>Data Security</p>
            <p className={styles.section_body}>The Service Provider is concerned about safeguarding the confidentiality of your information. They provide physical, electronic, and procedural safeguards to protect information processed and maintained.</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Encryption: Financial data accessed via Plaid is encrypted during transmission and storage.</li>
                <li className={styles.list_item}>Secure Connections: The Application uses secure connections (HTTPS) to communicate with servers.</li>
                <li className={styles.list_item}>Access Controls: User information is restricted to authorized personnel who need to know that information in order to operate, develop, or improve the Application.</li>
            </ul>
            
            <p className={styles.section_header}>Data Retention Policy</p>
            <p className={styles.section_body}>The Service Provider will retain User Provided Data for as long as you use the Application and for a reasonable time thereafter. Financial information obtained through Plaid is retained solely to provide the services within the Application and is not stored longer than necessary.</p>
            <p className={styles.section_body}>If you'd like the Service Provider to delete User Provided Data that you have provided via the Application, please contact them at contact@bytemarklabs.com, and they will respond within a reasonable time.</p>

            <p className={styles.section_header}>Opt-Out Rights</p>
            <p className={styles.section_body}>You can stop all collection of information by the Application easily by uninstalling it. You may use the standard uninstall processes available as part of your mobile device or via the mobile application marketplace or network.</p>
        
            <p className={styles.section_header}>Your Rights</p>
            <ul className={styles.list_container}>
                <li className={styles.list_item}>Access and Correction: You have the right to access and correct your personal information held by the Service Provider.</li>
                <li className={styles.list_item}>Data Deletion: You may request the deletion of your personal data by contacting the Service Provider.</li>
            </ul>

            <p className={styles.section_header}>Children’s Privacy</p>
            <p className={styles.section_body}>The Service Provider does not knowingly solicit data from or market to children under the age of 13.</p>
            <p className={styles.section_body}>The Application does not address anyone under the age of 13. If the Service Provider discovers that a child under 13 has provided personal information, they will immediately delete it from their servers. If you are a parent or guardian and you are aware that your child has provided personal information, please contact the Service Provider at contact@bytemarklabs.com so they can take necessary actions.</p>

            <p className={styles.section_header}>Changes to This Privacy Policy</p>
            <p className={styles.section_body}>This Privacy Policy may be updated from time to time for any reason. The Service Provider will notify you of any changes by updating this page with the new Privacy Policy. You are advised to consult this Privacy Policy regularly for any changes, as continued use is deemed approval of all changes.</p>
            <p className={styles.section_body}>This privacy policy is effective as of 2024-11-14</p>

            <p className={styles.section_header}>Your Consent</p>
            <p className={styles.section_body}>By using the Application, you are consenting to the processing of your information as set forth in this Privacy Policy now and as amended by us.</p>

            <p className={styles.section_header}>Contact Us</p>
            <p className={styles.section_body}>If you have any questions regarding privacy while using the Application or have questions about our practices, please contact the Service Provider via email at contact@bytemarklabs.com.</p>
        </div>
    );
}

export default PrivacyPolicy;
