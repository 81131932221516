import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Home from './routes/Home';
import About from './routes/About';
import Footer from "./components/Footer";

import BudgetmatePrivacyPolicy from "./routes/budgetmate/PrivacyPolicy";
import BudgetmateLandingPage from "./routes/budgetmate/LandingPage";

import { useEffect, useMemo } from "react";

function App() {
  const location = useLocation();
  const hideHeaderFooter = location.pathname.includes("budgetmate");

  const footerTextColor = useMemo(() => {
    if (location.pathname.includes("budgetmate/privacy-policy")) {
      return "#f5f5f5";
    } else if (location.pathname.includes("budgetmate")) {
      return "#000000";
    } else {
      return "#f5f5f5";
    }
  }, [location.pathname]);

  useEffect(() => {
    if (location.pathname.includes("budgetmate/privacy-policy")) {
      document.body.style.backgroundColor = "#4441f2";
    } else if (location.pathname.includes("budgetmate")) {
      document.body.style.backgroundColor = "#f5f5f5";
    } else {
      document.body.style.backgroundColor = "#090909";
    }
  }, [location.pathname]);

  return (
    <div className="app-wrapper">
      {!hideHeaderFooter && <Navbar />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/budgetmate" element={<BudgetmateLandingPage />} />
        <Route path="/budgetmate/privacy-policy" element={<BudgetmatePrivacyPolicy />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      <Footer textColor={footerTextColor} />
    </div>
  );
}

export default App;
