import styles from "../../styles/budgetmate/LandingPage.module.css";

export default function LandingPage() {
    return (
        <div className={styles.mainContent}>
            <img id={styles.appIcon} src="/appIcons/budgetmate.png" />

            <div className={styles.headlineSection}>
                <p className={styles.mainHeadline}>Take control of your finances effortlessly</p>
                <p className={styles.headline}>Connect your bank accounts, set personalized budgets, and track your progress throughout the month—all in one free app.</p>
                <a href="https://apps.apple.com/us/app/budgetmate/id6736376320" target="_blank">
                    <img src="/images/app-store.svg" id={styles.appStoreImage} />
                </a>
            </div>

            <div className={styles.contentSection}>
                

                <div className={styles.halfSection}>
                    <div className={styles.imageContainer} style={{backgroundImage: "url(/budgetmate-mocks/categorize-hands.jpeg)"}}>
                    </div>
                </div>

                <div className={styles.halfSection}>
                    <p>Classify transactions as 'Wants' or 'Needs'</p>
                </div>
            </div>

            <div className={styles.contentSection}>
                <div className={styles.halfSection}>
                    <div className={styles.imageContainer} style={{backgroundImage: "url(/budgetmate-mocks/khaki-shirt.jpeg)"}}>
                    </div>
                </div>

                <div className={styles.halfSection}>
                    <p>Track expenses by syncing your bank accounts in just a few taps using Plaid</p>
                </div>
            </div>

            <div className={styles.contentSection}>
                

                <div className={styles.halfSection}>
                    <div className={styles.imageContainer} style={{backgroundImage: "url(/budgetmate-mocks/budget-desk.jpeg)"}}>
                    </div>
                </div>

                <div className={styles.halfSection}>
                    <p>See how much you spend and save each month</p>
                </div>
            </div>
        </div>
    );
}